<template>
  <div
    class="departrue"
    v-loading="loading"
    element-loading-text="获取中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="station-box" v-for="(item, index) in cityList" :key="index">
      <div class="title">{{ item.cityName }}</div>
      <div class="station-list">
        <div v-for="(jtem, jndex) in item.stations" :key="jndex" @click="determine(jtem)">
          {{ jtem.fromSite }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { apiUrl } from "../ajax/config";
import { api } from "../ajax/api";
export default {
  data() {
    return {
      inputValue: "", //输入框的值
      loading: false, //等待效果
      searchList: [], //搜索结果列表
      stationList: [], //按省份是否隐藏站点
      cityList: [], //起始站列表
    };
  },
  computed: {
    ...mapState(["fromStation", "authToken", "departrue", "destination"]),
  },
  mounted() {
    this.getStartStations();
  },
  methods: {
    ...mapMutations(["setState"]),
    //获取起始站
    getStartStations: function () {
      let self = this;
      //   this.$toast.loading({
      //     message: "获取中",
      //     forbidClick: true,
      //     duration: 0,
      //   });
      this.loading = true;
      this.$post(
        apiUrl + api.getStartStations,
        {
          ticketCode: "car",
          typeId: 1,
          merge: 1,
          returnType: 1,
        },
        {
          "x-tenant-flag": 1,
          Authorization: "Bearer " + this.authToken,
        }
      ).then(
        (res) => {
          console.log("起始站", res.data[0].id);
          // this.$toast.clear();
          let len = res.data.length;
          let data;
          // let type;
          //第二种方法把云南提出来放最后自动展开
          if (res.data[0].id == 3434) {
            data = res.data.shift().children;
          } else {
            for (let i = 0; i < len - 1; i++) {
              // this.stationList[i] = false;
              if (res.data[i].id == 3434) {
                data = res.data.splice(i, 1).children;
                break;
              }
            }
          }
          this.cityList = data;
          this.loading = false;
          console.log("起始站sss", this.cityList);
        },
        (err) => {
          this.loading = false;
          //   this.$toast.clear();
          if (err.response.data.code == 401) {
            this.$emit("getAuthToken",'getStartStations');
            // if(this.$parent.getAuthToken()){
            //   console.log('获取鉴权成功')
            // }
          } else {
            // this.$toast(JSON.stringify(err.response.data));
          }
        }
      );
    },
    //确定起始站
    determine: function (obj) {
      this.setState({
        name: "departrue",
        key: {
          fromStationId: obj.fromStationId,
          fromSite: obj.fromSite,
        },
      });
      console.log('确定起始站',obj)
      if (this.destination) {
        this.setState({
          name: 'destination',
          key: {}
        });
      }
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.departrue {
  min-height: 330px;
}
.station-box {
  text-align: left;
  padding-left: 17%;
}
.title {
  font-size: 18px;
  color: #5cadad;
  font-weight: 600;
  margin: 20px 0;
}
.station-list {
  overflow: hidden;
}
.station-list > div {
  text-align: center;
  width: 100px;
  margin-right: 20px;
  min-height: 40px;
  line-height: 40px;
  font-size: 16px;
  float: left;
  cursor: pointer;
}
</style>