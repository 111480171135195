import Vue from 'vue'
import Vuex from 'vuex'

//添加在localStorage的变量
// token(登录权限) expire(token到期日期)  refreshToken(刷新token的权限) 

Vue.use(Vuex)
const state = {//公用的变量值
  userId: '',//用户id
  token: '',//token
  authToken: '',//鉴权token
  fromStation: [],//未分组的起始站列表
  departrue: {
    
  },//选择的起始站信息
  destination: {},//选择的目的站信息
  selectedDate: {},//主页选择的日期
  classId: '',//选择的班次Id
  toStationId: '',//到达站id
  passenger: [],//乘车人列表
  orderToken: '',//查询订单详情的订单号
};
const mutations = {//改变state的方法
  // 统一设置state变量值的方法
  setState(state, obj) {
    // obj 为{name: 变量名,key: 值}
    state[obj.name] = obj.key;
  },
  // 设置token
  setToken(state, obj) {
    state.token = obj.token;
    state.userId = obj.userId;
  },
};
const actions = {
  //异步设置token
  actionSetToken(context, obj) {
    context.commit('setToken', obj)
  },
}
export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
  }
})
