import axios from 'axios';
import { memberUrl, loginUrl } from './config'

axios.defaults.timeout = 30000;
axios.defaults.baseURL = memberUrl;

//http request 拦截器
axios.interceptors.request.use(
  config => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    //config.data = JSON.stringify(config.data);
    /* config.headers = {
       'Content-Type':'application/x-www-form-urlencoded'
     } */
    // if(token){
    //   config.params = {'token':token}
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
//http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },

  error => {
    //  this.$toast({
    //     message: '网络异常，请稍后重试！',
    //     position: 'bottom',
    //     duration: 3000
    //   }) 

    return Promise.reject(error)
  }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}, headers) {

  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
      headers: headers,
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, headers) {
  if (!headers) {
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
  }
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: headers
    }).then(response => {
      resolve(response.data);
    }, err => {
      reject(err)
    }).catch(req => {
      reject(req)
    })
  })
}

/**
* 封装patch请求
* @param url
* @param data
* @returns {Promise}
*/

export function patch(url, data = {}, headers) {
  if (!headers) {
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
  }
  return new Promise((resolve, reject) => {
    axios.patch(url, data, {
      headers: headers
    })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
* 封装put请求
* @param url
* @param data
* @returns {Promise}
*/

export function put(url, data = {}, headers) {
  if (!headers) {
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
  }
  return new Promise((resolve, reject) => {
    axios.put(url, data, {
      headers: headers
    })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}
export function Delete(url, data = {}, Headers) {
  return new Promise((resolve, reject) => {
    axios.delete(url, data, {
      headers: headers
    })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}