<template>
  <div id="app">
    <el-container>
      <el-header height="195px">
        <div class="header-box">
          <div class="header">
            <img src="./assets/logo.png" alt="" />
            <div class="link">
              <div>
                <img src="./assets/weixin.jpg" alt="" />
                <span>微信</span>
              </div>
              <div>
                <img src="./assets/Android.png" alt="" />
                <span>Android</span>
              </div>
              <div>
                <span>客服热线</span>
                <div>
                  <i class="el-icon-phone-outline"></i>
                  <span>4006997777</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-box">
          <div class="nav">
            <span></span>
            <div @click="goIndex">首页</div>
            <div><a href="http://www.12306.cn/" target="_blank">火车票</a></div>
            <div><a href="http://www.csair.com/" target="_blank">飞机票</a></div>
            <div><a href="http://www.ctrip.com/" target="_blank">旅游</a></div>
            <div><a href="http://kunming.8684.cn/" target="_blank">公交</a></div>
            <div @click="goMine">个人中心</div>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view @getAuthToken="getAuthToken" ref="child"></router-view>
      </el-main>
      <el-footer height="130px">
        <div class="link-cooperate">
          <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备16006330号-1</a>
        </div>
        <div class="link-cooperate">
          <span>友情链接：</span>
          <a href="http://jtyst.yn.gov.cn/" target="_blank">云南省交通运输厅</a>
          <a href="http://ynyz.yn.gov.cn/" target="_blank">云南省道路运输管理局</a>
          <a href="http://www.12306.cn/" target="_blank">中国铁路客户服务中心</a>
          <a href="http://www.csair.com/" target="_blank">中国南方航空</a>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// import Home from './components/Home.vue'
import {apiUrl, appId, appKey, memberUrl} from "./ajax/config.js";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { api, member } from "./ajax/api";
import md5 from "js-md5";
export default {
  name: 'App',
  components: {
    // Home
  },
  computed: {
    ...mapState(["authToken", "fromStation", "openid"]),
  },
  created() {
    console.log('aap.vue——created')
    if (!this.authToken) {
      this.getAuthToken();
    }
    let tokenStotage = sessionStorage.getItem("token");
    if (!this.token && tokenStotage) {
      this.$store.commit("setToken", {
        token: tokenStotage,
        userId: sessionStorage.getItem("userId"),
      });
    } else if (this.token && !tokenStotage) {
      this.setState({
        name: "token",
        key: "",
      });
      this.setState({
        name: "userId",
        key: "",
      });
    }
  },
  mounted() {
    console.log('app.vue的mounted周期')
    if (window.performance.navigation.type == 1) {
      this.$router.replace("/");
    } else {
      console.log("首次被加载");
    }
  },

  methods: {
    ...mapMutations(["setState", "setToken"]),
    ...mapActions(["actionSetToken"]),
    //回主页
    goIndex(){
      this.$router.replace({
        path: '/'
      })
    },
    //回个人中心
    goMine(){
      this.$router.replace({
        path: '/mine'
      })
    },
    //获取鉴权token
    getAuthToken(name) {
      let time = new Date().getTime();
      // let str =
      //   "username=vs353m5of437a3b5883k&time=" +
      //   time +
      //   "&key=eq7l3k4858tbf6w226x3xydyg4avkc8a";
      // let str =
      //   "username=vs353m5of437a3b5883k&time=" +
      //   time +
      //   "&key=0p9c6i8ao0gi0yel83d426f6zl6p3f5i";
      let str = "username="+appId+"&time="+time+"&key="+appKey;
      console.log("appId")
      console.log(appId)
      console.log("appKey")
      console.log(appKey)
      str.toUpperCase();
      let pwd = md5(str);
      // this.$toast.loading({
      //   message: "鉴权中",
      //   forbidClick: true,
      //   duration: 0,
      // });
      this.$post(
        apiUrl + api.authToken,
        this.$qs.stringify({
          grant_type: "password",
          username: appId,
          time: time,
          sign: pwd,
        }),
        {
          "x-tenant-flag": 1,
          Authorization: "Basic dGlja2V0OnRpY2tldA==",
          "Content-Type": "application/x-www-form-urlencoded",
        }
      ).then(
        (res) => {
          console.log("鉴权", res);
          // this.$toast.clear();
          if (res.code == 0) {
            this.setState({
              name: "authToken",
              key: res.access_token,
            });
            if (name) {
              this.$refs.child[name]();//重新鉴权以后调用子页面的接口
            }
            // if (!this.fromStation[0]) {
            //   this.getFromStation();
            // }
            // this.getToStation();
          } else {
            // this.$toast(res.msg);
          }
        },
        (err) => {
          // this.$toast.clear();
          // this.$toast(JSON.stringify(err.response.data));
        }
      );
    },
    // 获取目的站
    getToStation() {
      // this.$toast.loading({
      //   message: "获取中",
      //   forbidClick: true,
      //   duration: 0,
      // });
      this.$post(
        apiUrl + api.getToStation,
        {
          fromStationId: this.departrue.fromStationId,
          // merge: 1,
          // returnType: 1,
        },
        {
          "x-tenant-flag": 1,
          Authorization: "Bearer " + this.authToken,
        }
      ).then(
        (res) => {
          console.log("目的站", res);
          if (res.code == 0) {
            // this.stationData = res.data;
            // let len = this.stationData.length;
            // for (let i = 0; i < len; i++) {
            //   this.indexList.push(this.stationData[i].groupName);
            // }
            // this.reCall(res.data);
          } else {
            // this.$toast(res.msg);
          }
        },
        (err) => {
          // this.$toast.clear();
          if (err.response.data.code == 401) {
            // this.$parent.getAuthToken();
          } else {
            // this.$toast(JSON.stringify(err.response.data));
          }
        }
      );
    },
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

#app {
  font-family: consolas, "Microsoft YaHei", SimSun, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  /* overflow: auto; */
}

#app>.el-container {
  /* position: relative; */
  min-height: 100%;
  /* overflow: auto; */
}

.el-container>.el-header {
  width: 100%;
  padding: 0;
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* z-index: 1; */
}

.header-box {
  width: 100%;
  height: 150px;
  background: #F0F0F0;
  /* position: absolute;  */
}

.header {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

/* 链接 */
.link {
  display: flex;
  align-items: center;
  margin-left: 100px;
}

.link>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.link>div:nth-of-type(2),
.link>div:nth-of-type(3) {
  margin-left: 30px;
}

.link>div>img {
  width: 110px;
  height: 110px;
  margin-bottom: 5px;
}

.link>div:nth-of-type(4) {
  margin-left: 30px;
}

.link>div>div {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 16px;
}

.link>div>div>i {
  font-size: 37px;
  margin-right: 5px;
}

/* 登录注册 */
.login {
  width: 710px;
  display: flex;
  font-size: 14px;
  color: #999999;
  line-height: 29px;
}

.login>p:nth-of-type(1) {
  margin-right: 20px;
}

.login>p:nth-of-type(1):hover,
.login>p:nth-of-type(2):hover {
  color: #ff6257;
  cursor: pointer;
}

/* 导航栏 */
.nav-box {
  width: 100%;
  height: 45px;
  background: #5cadad;
}

.nav {
  width: 66%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
}

.nav>span {
  width: 2px;
  background: #ffffff;
  margin-right: 20px;
  height: 16px;
}

.nav>div {
  margin-right: 65px;
}

.nav>div>a {
  color: #ffffff;
  text-decoration: none;
}

.nav>div:hover {
  cursor: pointer;
  font-weight: 600;
}

.el-container>.el-main {
  width: 100%;
  padding: 0;
  min-height: 450px;
  /* position: absolute; */
  /* top: 195px; */
  /* left: 0; */
  /* margin-bottom: 135px; */
  /* z-index: 1; */
}

/* 底部 */
.el-container>.el-footer {
  border-top: 4px solid #ededed;
  width: 100%;
  background: #F8F8F8;
  /* position: fixed; */
  /* bottom: 0; */
  /* left: 0; */
  padding: 0;
}

.ip {
  font-size: 14px;
  color: #5cadad;
  margin-top: 25px;
}

.link-cooperate {
  font-size: 14px;
  margin-top: 15px;
}

.link-cooperate>a {
  text-decoration: none;
  margin-right: 15px;
  color: #5cadad;
}

.link-cooperate>a:hover {
  color: RGB(230, 81, 0);
}
</style>
