<template>
    <div class="order-list" v-loading="loading" element-loading-text="获取中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="title-list">
            <div :class="tabbarList[0] ? 'title-select' : ''" @click="tabbarSelect(0)">全部订单</div>
            <div :class="tabbarList[1] ? 'title-select' : ''" @click="tabbarSelect(1)">待支付</div>
            <div :class="tabbarList[2] ? 'title-select' : ''" @click="tabbarSelect(2)">已出票</div>
            <div :class="tabbarList[3] ? 'title-select' : ''" @click="tabbarSelect(3)">未出票</div>
        </div>
        <div class="list-box">
            <el-table :data="orderList" style="width: 100%">
                <el-table-column label="线路信息" width="280">
                    <template slot-scope="scope">
                        <div style="color: #000;font-weight: 700;font-size: 16px;">{{ scope.row.classInfo.lineName }}
                        </div>
                        <div>出发时间：{{ scope.row.classInfo.classDateTime }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="订单金额" width="180" align="center">
                    <template slot-scope="scope">
                        <div style="color: #ff6257;">￥{{ scope.row.payFare }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="orderToken" label="订单编号" width="230" align="center">
                </el-table-column>
                <el-table-column label="订单状态" align="center">
                    <template slot-scope="scope">
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == -1">已取消</div>
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == 1">待支付</div>
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == 3">已出票</div>
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == 4">已取票</div>
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == 5">已退票</div>
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == 6">已改签</div>
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == 7">部分退票</div>
                        <div style="color: #000;font-weight: 700;" v-if="scope.row.orderStatus == 8">部分改签</div>
                    </template>
                </el-table-column>
                <el-table-column label="订单操作" align="center">
                    <template slot-scope="scope">
                        <div style="cursor: pointer;" @click="getOrderDetail(scope.row.orderToken)">查看订单</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>
<script>
import { memberUrl, apiUrl } from "../ajax/config.js";
import { mapState, mapMutations } from "vuex";
import { member, api } from "../ajax/api";
export default {
    name: 'OrderList',
    computed: {
        ...mapState(["userId", "authToken"]),
    },
    data() {
        return {
            orderList: [],
            loading: false,
            pageNum: 1,//当前页码
            tabbarList: [true, false, false, false],
            total: 0,//订单总数量
        };
    },

    mounted() {
        this.getOrderList("", "");
    },

    methods: {
        ...mapMutations(["setState"]),
        //tabbar栏选择切换
        tabbarSelect(id) {
            this.loading = false;
            this.pageNum = 1;
            console.log('切换标题栏', id)
            switch (id) {
                case 0:
                    this.tabbarList = [true, false, false, false];
                    this.getOrderList("", "");
                    break;
                case 1:
                    this.tabbarList = [false, true, false, false];
                    this.getOrderList(1, 0);
                    break;
                case 2:
                    this.tabbarList = [false, false, true, false];
                    this.getOrderList(3, 1);
                    break;
                case 3:
                    this.tabbarList = [false, false, false, true];
                    this.getOrderList(-1, "");
                    break;
            }
        },
        //查询订单列表
        getOrderList(orderStatus, payStatus) {
            this.loading = true;
            this.$post(
                apiUrl + api.getOrderList,
                {
                    appUserId: this.userId,
                    page: this.pageNum,
                    pageSize: 10,
                    orderStatus: orderStatus,
                    typeId: 1,
                    payStatus: payStatus,
                },
                {
                    "x-tenant-flag": 1,
                    Authorization: "Bearer " + this.authToken,
                }
            ).then(
                (res) => {
                    this.loading = false;
                    if (res.code == 0 && res.data[0]) {
                        this.total = res.total;
                        this.orderList = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                    }
                },
                (err) => {
                    this.loading = false;
                    this.$message({
                        message: JSON.stringify(err.response.data),
                        type: "error",
                    });
                }
            );
        },
        //分页的当前页发生改变
        handleCurrentChange(val) {
            console.log('当前页发生改变', val)
            this.pageNum = val;
            if (this.tabbarList[0]) {
                this.getOrderList("", "");
            } else if (this.tabbarList[1]) {
                this.getOrderList(1, 0);
            } else if (this.tabbarList[2]) {
                this.getOrderList(3, 1);
            } else if (this.tabbarList[3]) {
                this.getOrderList(-1, "");
            }
        },
        //去订单详情页
        getOrderDetail(orderToken) {
            this.setState({
                name: "orderToken",
                key: orderToken,
            });
            this.$emit('orderListToggle', false)
            this.$emit('orderDetailToggle', true)
        },

    },
};
</script>
<style scoped>
.order-list {
    width: 100%;
}

.title-list {
    width: 100%;
    display: flex;
    border-bottom: 2px solid #eee;
    align-items: center;
}

.title-list>div {
    margin-right: 10px;
    cursor: pointer;
    font-size: 18px;
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    color: #333;
}

.title-select {
    color: #00c777;
    font-weight: 700;
    border-bottom: 2px solid #00c777;
}

.list-box {
    margin-bottom: 20px;
}
</style>