<template>
    <div class="order-detail" v-if="orderInfo.orderToken" v-loading="loading" element-loading-text="获取中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="title">
            <div>订单号：<span style="font-weight: 700;font-size: 16px;">{{ orderInfo.orderToken }}</span></div>
            <div>
                订单状态：
                <span v-if="orderInfo.orderStatus == -1" style="font-weight: 700;">已取消</span>
                <span v-if="orderInfo.orderStatus == 1" style="font-weight: 700;">待支付</span>
                <span v-if="orderInfo.orderStatus == 3" style="font-weight: 700;">已出票</span>
                <span v-if="orderInfo.orderStatus == 4" style="font-weight: 700;">已取票</span>
                <span v-if="orderInfo.orderStatus == 5" style="font-weight: 700;">已退票</span>
                <span v-if="orderInfo.orderStatus == 6" style="font-weight: 700;">已改签</span>
                <span v-if="orderInfo.orderStatus == 7" style="font-weight: 700;">部分退票</span>
                <span v-if="orderInfo.orderStatus == 8" style="font-weight: 700;">部分改签</span>
            </div>
            <div>出发时间：{{ orderInfo.classInfo.classDateTime }}</div>
        </div>
        <div class="order-info">
            <div class="info-title">行程信息</div>
            <div class="line-info">
                <div>
                    <div>计划车型</div>
                    <div>客车</div>
                </div>
                <div>
                    <div>出发</div>
                    <div>{{ orderInfo.classInfo.fromName }}</div>
                </div>
                <div>
                    <div>到达</div>
                    <div>{{ orderInfo.classInfo.toName }}</div>
                </div>
                <div>
                    <div>票款</div>
                    <div style="color: #ff6257;">￥{{ orderInfo.ticketPrice }}</div>
                </div>
            </div>
            <div class="info-title">乘客信息</div>
            <div class="passenger-info">
                <div>
                    <div>乘客姓名</div>
                    <div>电话号码</div>
                    <div>证件类型</div>
                    <div>证件号码</div>
                </div>
                <div class="passenger-list" v-for="(item, index) in orderInfo.passengerInfo" :key="index">
                    <div>{{ item.passengerName }}</div>
                    <div>{{ item.phone }}</div>
                    <div>身份证</div>
                    <div>{{ item.card }}</div>
                </div>
            </div>
            <div class="info-title">支付信息</div>
            <div class="pay-info">
                支付金额:
                <span>￥{{ orderInfo.payFare }}</span>（票价<span>￥{{ orderInfo.ticketPrice }}*{{ orderInfo.seatCount
}}</span> +
                服务费<span>￥{{ orderInfo.serverPrice }}*{{ orderInfo.seatCount }}</span>）
            </div>
            <div class="info-title">联系人信息</div>
            <div class="contact-info">
                <div>姓名: {{ orderInfo.bookInfo.bookerName }}</div>
                <div>电话号码: {{ orderInfo.bookInfo.phone }}</div>
            </div>
        </div>
        <!-- 底部按钮列表 -->
        <div class="btn-box">
            <el-button type="success" size="mini" v-if="orderInfo.orderStatus == 1 && orderInfo.payStatus == 0"
                @click="pay">付款</el-button>
            <el-button type="info" size="mini" v-if="orderInfo.orderStatus == 1" @click="cancel">取消订单</el-button>
            <el-button type="info" size="mini" v-if="
    (orderInfo.orderStatus == 3 || orderInfo.orderStatus == 7) &&
    orderInfo.payStatus == 1 &&
    orderInfo.showRuturn
" @click="returnTicket">退票</el-button>
            <el-button type="info" size="mini" @click="showOrderList">返回订单列表</el-button>
        </div>
    </div>
</template>
<script>
import { memberUrl, apiUrl } from "../ajax/config.js";
import { mapState, mapMutations } from "vuex";
import { member, api } from "../ajax/api";
export default {
    name: 'OrderDetail',
    computed: {
        ...mapState(["userId", "authToken", "orderToken"]),
    },

    data() {
        return {
            orderInfo: {},
            loading: false,
        };
    },
    created() {

    },

    mounted() {
        console.log('订单编号', this.orderToken)
        this.queryOrder()
    },

    methods: {
        //查询订单详情
        queryOrder() {
            this.$post(apiUrl + api.queryorderDetail, {
                orderToken: this.orderToken
            }, {
                "x-tenant-flag": 1,
                Authorization: "Bearer " + this.authToken,
            }).then(res => {
                console.log('订单详情', res)
                if (res.data[0]) {
                    this.orderInfo = res.data[0];
                    let nowDate = new Date();
                    let nowTime =
                        nowDate.getFullYear() +
                        "-" +
                        (nowDate.getMonth() + 1 < 10
                            ? "0" + (nowDate.getMonth() + 1)
                            : nowDate.getMonth() + 1) +
                        "-" +
                        (nowDate.getDate() < 10
                            ? "0" + nowDate.getDate()
                            : nowDate.getDate());
                    if (res.data[0].classInfo.classDate >= nowTime) {
                        this.orderInfo.showRuturn = true;
                    } else {
                        this.orderInfo.showRuturn = false;
                    }
                } else {
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                }
            }, err => {
                this.$message.error(JSON.stringify(err.response.data));
            })
        },
        //返回订单列表
        showOrderList() {
            this.$emit('orderListToggle', true)
            this.$emit('orderDetailToggle', false)
        },
        //取消订单
        cancel() {
            this.loading = true;
            this.$post(
                apiUrl + api.cancelOrder,
                {
                    orderToken: this.orderInfo.orderToken,
                },
                {
                    "x-tenant-flag": 1,
                    Authorization: "Bearer " + this.authToken,
                }
            ).then(
                (res) => {
                    console.log("取消订单", res);
                    this.loading = false;
                    if (res.code == 0) {
                        this.$message({
                            message: '取消成功',
                            type: 'success'
                        })
                        this.orderInfo.orderStatus = -1;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        })
                    }
                },
                (err) => {
                    this.loading = false;
                    this.$message({
                        message: JSON.stringify(err.response.data),
                        type: "error",
                    });
                }
            );
        },
        //退票
        returnTicket() {
            this.$confirm('退票将产生退票手续, 是否确认退票?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                this
                    .$post(
                        apiUrl + api.allTicketReturn,
                        {
                            orderToken: this.orderInfo.orderToken,
                        },
                        {
                            "x-tenant-flag": 1,
                            Authorization: "Bearer " + this.authToken,
                        }
                    )
                    .then(
                        (res) => {
                            console.log("退票", res);
                            this.loading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: '退票成功',
                                    type: 'success'
                                })
                                this.orderInfo.orderStatus = 5;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'warning'
                                })
                            }
                        },
                        (err) => {
                            this.loading = false;
                            this.$message({
                                message: JSON.stringify(err.response.data),
                                type: "error",
                            });
                        }
                    );
            }).catch(() => {
                
            });
        },
        //支付
        pay() {
            this.$router.push({
                name: "Pay",
                params: {
                    orderInfo: this.orderInfo,
                },
            });
        },
    },
};
</script>
<style scoped>
.title {
    display: flex;
    padding: 10px 0;
    color: #333;
}

.title>div {
    margin-right: 20px;
    font-size: 14px;
}

.order-info {
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
}

.info-title {
    height: 30px;
    line-height: 30px;
    background: #f0f0f0;
    padding-left: 10px;
    text-align: left;
}

.line-info {
    display: flex;
    padding: 20px;
}

.line-info>div {
    min-width: 170px;
    line-height: 30px;
    text-align: left;
}

.line-info>div>div:nth-of-type(1) {
    color: #999;
}

.passenger-info,
.pay-info,
.contact-info {
    padding: 20px;
    text-align: left;
}

.passenger-info>div {
    display: flex;
    margin-bottom: 10px;
}

.passenger-info>div:nth-of-type(1) {
    color: #999;
}

.passenger-info>div>div {
    width: 170px;
}

.pay-info>span {
    color: #ff6257;
}

.contact-info {
    display: flex;
}

.contact-info>div {
    width: 170px;
}

.btn-box {
    margin-top: 20px;
    margin-bottom: 30px;
}
</style>