import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Departrue from '@/components/Departrue'
import Timeselect from "@/components/Timeselect"
import Order from "@/components/order"
import Pay from "@/components/pay"
import Mine from "@/components/mine"
Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/departrue',
            name: 'Departrue',
            component: Departrue
        },
        {
            path: '/timeselect',
            name: 'Timeselect',
            component: Timeselect
        },
        {
            path: '/order',
            name: 'Order',
            component: Order
        },
        {
            path: '/pay',
            name: 'Pay',
            component: Pay
        },
        {
            path: '/mine',
            name: 'Mine',
            component: Mine
        },
    ]
})