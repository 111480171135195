<template>
  <div class="select-class" v-loading="loading" element-loading-text="获取中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="box">
      <div class="nav-top">
        <div class="fromSite">
          <span>出发地点</span>
          <div @click="selectStation">
            <el-input placeholder="请输入中文/简拼" suffix-icon="el-icon-location-outline" size="small" :readonly="true"
              v-model="fromSite">
            </el-input>
          </div>
        </div>
        <div class="fromSite">
          <span>目的地点</span>
          <div>
            <el-input placeholder="请输入中文/简拼" suffix-icon="el-icon-location-outline" size="small" v-model="toSite"
              @input="StartInput" slot="reference">
            </el-input>
            <el-popover placement="bottom" width="0" trigger="manual" v-model="isShow"
              :popper-class="searchList[0] ? 'popper' : 'poppers'">
              <div v-show="searchList[0]" v-for="(item, index) in searchList" :key="index" class="tostation"
                @click="selectToStation(item)">
                {{ item.toStationName }}
              </div>
              <div style="padding: 6px 12px" v-show="!searchList[0]">
                未匹配到站点信息
              </div>
            </el-popover>
          </div>
        </div>
        <div class="fromSite">
          <span>出发日期</span>
          <div>
            <el-date-picker v-model="date" type="date" placeholder="选择日期" size="small" :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </div>
        <div class="query" @click="query">查询</div>
      </div>
      <div class="time">
        <div class="icon" @click="prevWeek">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="time-box">
          <div :class="[item.select ? 'time-select' : '']" v-for="(item, index) in timeList" :key="index"
            @click="dateSelect(item, index)">
            <p>{{ item.date }}</p>
            <p>{{ item.week }}</p>
          </div>
        </div>
        <div class="icon" @click="nextWeek">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- <div class="filter">
      <div> 
        <span>客运站</span>
        <el-radio v-model="radio" label="2">安宁客运站</el-radio>
      </div>
    </div> -->
      <div class="line"></div>
      <div class="class-list">
        <div class="title">
          <div class="size1" style="text-align: left">车型/车次</div>
          <div class="size2" style="text-align: left">出发/到达</div>
          <div class="size1">发车时间</div>
          <div class="size1">票价</div>
          <div class="size1">余票</div>
          <div class="size1">里程/车程</div>
          <div class="size1">汽车票预定</div>
        </div>
        <div class="list" v-show="!noClassShow" v-for="(item, index) in classList" :key="index">
          <div class="column size1">
            <span>{{ item.carType }}</span>
            <span>{{ item.classCode }}</span>
          </div>
          <div class="column size2">
            <span>{{ item.station.name }}</span>
            <span>{{ item.toStation.stationName }}</span>
          </div>
          <div class="size1">{{ item.classTime }}</div>
          <div class="size1">￥{{ item.price }}</div>
          <div class="size1">
            <span>{{ item.remSeat }}</span>
            <!-- <span>/{{item.remSeat}}</span> -->
          </div>
          <div class="size1">{{ item.mileage }}公里</div>
          <div class="button size1" @click="buyTicket(item)">网上购票</div>
        </div>
        <div class="no-class" v-show="noClassShow">
          <p>没有查询到指定的班次信息</p>
          <p>或当天无可购票班次，请尝试选择其他日期</p>
        </div>
      </div>
    </div>
    <!-- 登录框 -->
    <el-dialog title="七彩出行" :visible.sync="loginVisible" width="20%" center>
      <div class="code-tip" v-show="!codeTimeHidden">
        <i class="el-icon-circle-check"></i>动态验证码已经发送到您手机，请注意查收。
      </div>
      <div>
        <el-input placeholder="请输入手机号码" v-model="phone" clearable>
        </el-input>
      </div>
      <div class="code-box">
        <el-input placeholder="请输入手机号码" v-model="code">
        </el-input>
        <div v-show="codeTimeHidden" @click="getCode">获取验证码</div>
        <div v-show="!codeTimeHidden">{{ codeTime }}s后重新发送</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="login">登录</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { memberUrl, apiUrl } from "../ajax/config.js";
import { mapState, mapMutations } from "vuex";
import { member, api } from "../ajax/api";
import util from "../util/util";
export default {
  computed: {
    ...mapState(["token", "departrue", "destination", "authToken"]),
  },
  data() {
    return {
      fromSite: "", //起始站
      toSite: "", //目的地
      date: "", //日历选择器的日期
      nowDate: "", //当前日期
      toStationList: [], //目的站列表(筛选前源数据)
      searchList: [], //筛选后数据
      isShow: false, //目的站选择框是否显示
      pickerOptions: {}, //日历选择器配置项
      weekArray: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      timeList: [], //横向日期选择
      // selectDate: null, //第一次进入时选中的日期
      loading: false, //查询班次时的等待动画是否开启
      startStationList: [], //起始站筛选项
      classList: [], //班次列表
      oldClassList: [], //多存一份班次数据做排序
      noClassShow: false, //无班次提示是否显示
      loginVisible: false,//登录遮罩层是否显示
      phone: '',//电话号码
      code: '',//验证码
      codeTimeHidden: true,//验证码倒计时是否隐藏
      codeTime: 120,//获取验证码倒计时时间
      timer: null,//验证码倒计时定时器
      selectClassId: '',//选择班次的classId
    };
  },
  created() {
    let week = 7 * 24 * 3600 * 1000;
    this.pickerOptions.disabledDate = function (time) {
      let week = 7 * 24 * 3600 * 1000;
      return (
        time.getTime() < Date.now() - 1 * 24 * 3600 * 1000 ||
        time.getTime() > Date.now() + week
      );
    };
    this.pickerOptions.onPick = function () {
      this.queryShift();
    };
    let date = new Date();
    this.nowDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  },
  mounted() {
    console.log("路由参数", this.$route.params);
    // 起始目的站赋值
    this.fromSite = this.departrue.fromSite ? this.departrue.fromSite : "";
    this.toSite = this.destination.toStationName
      ? this.destination.toStationName
      : "";
    this.date = this.$route.params.date;
    // this.selectDate = this.$route.params.date;
    this.toStationList = this.$route.params.toStationList;
    let nowTime = new Date(this.date);
    for (let i = 0; i < 7; i++) {
      if (i == 0) {
        this.timeList.push({
          date: this.date,
          week: this.weekArray[new Date(this.date).getDay()],
          select: true,
        });
      } else {
        this.timeList.push({
          date: util.formatTime(
            new Date(nowTime.setDate(nowTime.getDate() + 1))
          ),
          week: this.weekArray[new Date(nowTime).getDay()],
          select: false,
        });
      }
    }
    this.queryShift();
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    ...mapMutations(["setState"]),
    //去选择起始站
    selectStation() {
      this.$router.push({
        path: "/departrue",
      });
    },
    //目的地点输入
    StartInput(e) {
      let len = this.toStationList.length;
      this.searchList = [];
      if (!e) {
        this.isShow = false;
      } else {
        let reg = /^[A-Za-z]*$/; //判断输入的是否是字母
        if (reg.test(e)) {
          //如果是字母
          if (e.length == 1) {
            //如果输入是一个字母
            let toCase = e.toUpperCase();
            for (let i = 0; i < len; i++) {
              if (this.toStationList[i].toAbbr.charAt(0) == toCase) {
                this.searchList.push(this.toStationList[i]);
              }
            }
          } else {
            let toCase = e.toUpperCase();
            for (let i = 0; i < len; i++) {
              if (
                this.toStationList[i].toAbbr == toCase ||
                this.toStationList[i].toAbbr.indexOf(toCase) >= 0
              ) {
                this.searchList.push(this.toStationList[i]);
              }
            }
          }
        } else {
          //不是字母一律当中文处理
          for (let i = 0; i < len; i++) {
            if (this.toStationList[i].toStationName.indexOf(e) >= 0) {
              this.searchList.push(this.toStationList[i]);
            }
          }
        }
        console.log("筛选后", this.searchList);
        this.isShow = true;
      }
    },
    //选择目的站
    selectToStation(obj) {
      this.setState({
        name: "destination",
        key: {
          toStationId: obj.toStationId,
          toStationName: obj.toStationName,
        },
      });
      this.toSite = obj.toStationName;
      this.isShow = false;
    },
    //查询班次
    query() {
      this.queryShift();
      let date = util.formatTime(this.date);
      let len = this.timeList.length;
      for (let i = 0; i < len; i++) {
        if (this.timeList[i].date == date) {
          this.timeList[i].select = true;
        } else {
          this.timeList[i].select = false;
        }
      }
    },
    //下一个星期日期选择
    nextWeek() {
      let date = this.timeList[this.timeList.length - 1].date;
      let nowTime = new Date(date);
      this.timeList = [];
      for (let i = 0; i < 7; i++) {
        if (i == 0) {
          this.timeList.push({
            date: date,
            week: this.weekArray[new Date(date).getDay()],
            select: date == util.formatTime(new Date(this.date)) ? true : false,
          });
        } else {
          let date2 = util.formatTime(
            new Date(nowTime.setDate(nowTime.getDate() + 1))
          );
          this.timeList.push({
            date: date2,
            week: this.weekArray[new Date(nowTime).getDay()],
            select: date2 == util.formatTime(new Date(this.date)) ? true : false,
          });
        }
      }
    },
    //上一个星期日期选择
    prevWeek() {
      if (new Date(this.timeList[0].date).getTime() > new Date(this.nowDate)) {
        // console.log('上个星期',this.timeList,this.timeList[this.timeList.length - 1].date)
        let date = this.timeList[0].date;
        let nowTime = new Date(date);
        this.timeList = [];
        for (let i = 0; i < 7; i++) {
          if (i == 0) {
            this.timeList.push({
              date: date,
              week: this.weekArray[new Date(date).getDay()],
              select: date == util.formatTime(new Date(this.date)) ? true : false,
            });
          } else {
            let date2 = util.formatTime(
              new Date(nowTime.setDate(nowTime.getDate() - 1))
            );
            this.timeList.unshift({
              date: date2,
              week: this.weekArray[new Date(nowTime).getDay()],
              select: date2 == util.formatTime(new Date(this.date)) ? true : false,
            });
          }
        }
      }
    },
    //横向日期选择器选择日期
    dateSelect(obj, index) {
      console.log("选择日期", obj);
      if (new Date(obj.date).getTime() > new Date(this.nowDate)) {
        let len = this.timeList.length;
        for (let i = 0; i < len; i++) {
          if (i == index) {
            this.timeList[index].select = true;
          } else {
            this.timeList[i].select = false;
          }
        }
        this.date = obj.date;
        this.queryShift();
      } else {
        this.$message({
          message: "只能选择当前日期及以后的日期。",
          type: "warning",
        });
      }
    },
    //购票
    buyTicket(obj) {
      console.log('购票', obj)
      if (obj.remSeat > 0 && this.authToken && this.token) {
        this.setState({
          name: "classId",
          key: obj.classId,
        });
        this.$router.replace({
          path: '/order'
        })
      } else if (obj.remSeat <= 0) {
        this.$message({
          message: "该班次已售完，请选择其他班次",
          type: 'info'
        })
      } else if (!this.authToken) {
        this.$parent.getAuthToken();
      } else if (!this.token) {
        this.loginVisible = true;
        this.selectClassId = obj.classId;
      }
    },
    // 查询班次
    queryShift() {
      this.loading = true;
      this.$post(
        apiUrl + api.queryClass,
        {
          classDate: util.formatTime(new Date(this.date)),
          fromStationId: this.departrue.fromStationId,
          toStationId: this.destination.toStationId,
          showPassStation: 2,
        },
        {
          "x-tenant-flag": 1,
          Authorization: "Bearer " + this.authToken,
        }
      ).then(
        (res) => {
          console.log("查询班次", res);
          if (res.code == 0 && res.data.length > 0) {
            let len = res.data.length;
            //添加筛选起始站的筛选项
            for (let i = 0; i < len; i++) {
              if (
                JSON.stringify(this.startStationList).indexOf(
                  JSON.stringify({
                    name: res.data[i].station.name,
                    show: true,
                  })
                ) == -1
              ) {
                this.startStationList.push({
                  name: res.data[i].station.name,
                  show: true,
                });
              }
            }
            this.oldClassList = res.data;
            this.classList = res.data;
            this.noClassShow = false;
            this.loading = false;
          } else if (res.code == 0 && res.data.length == 0) {
            this.oldClassList = [];
            this.classList = [];
            this.startStationList = [{ name: "不限", show: true }];
            this.loading = false;
            this.noClassShow = true;
          } else {
            this.noClassShow = false;
            this.loading = false;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        },
        (err) => {
          this.loading = false;
          if (err.response.data.code == 401) {
            this.$emit("getAuthToken", "queryShift");
          } else {
            this.$toast();
            this.$message({
              message: JSON.stringify(err.response.data),
              type: "error",
            });
          }
        }
      );
    },
    //获取登录验证码
    getCode() {
      if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
        this.codeTimeHidden = false;
        this.codeDown()
        this.$post(
          memberUrl + member.getVerifyCode,
          { phone: this.phone, scenes: 2 },
          { "x-tenant-flag": 1 }
        )
          .then(
            (res) => {
              console.log("验证码", res);
              if (res.code == 0) {
                this.$message({
                  message: '发送成功。',
                  type: 'success'
                });
              }
            },
            (err) => {
              this.$message.error(JSON.stringify(err.response.data));
            }
          );
      } else {
        this.$message({
          message: '请输入正确的手机号码',
          type: 'warning'
        });
      }
    },
    //验证码倒计时
    codeDown() {
      let self = this;
      self.timer = setInterval(function () {
        if (self.codeTime > 0) {
          self.codeTime -= 1;
        } else {
          clearInterval(self.timer)
          self.codeTimeHidden = true;
          self.codeTime = 120;
        }
      }, 1000)
    },
    //登录
    login() {
      if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone) && this.code) {
        this.$post(
          memberUrl + member.login,
          this.$qs.stringify({
            loginType: 2,
            username: this.phone,
            category: 1,
            smsCode: this.code,
            grant_type: "password",
          }),
          {
            "x-tenant-flag": 1,
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Basic YXBwOmFwcA==",
          }
        ).then(
          (res) => {
            console.log("登录", res);
            if (res.access_token) {
              this.code = "";
              this.$store.commit("setToken", {
                token: res.access_token,
                userId: res.userId,
              });
              sessionStorage.setItem("token", res.access_token);
              sessionStorage.setItem("userId", res.userId);
              this.$message({
                message: '登录成功。',
                type: 'success'
              });
              this.setState({
                name: "classId",
                key: this.selectClassId,
              });
              this.$router.replace({
                path: "/order",
              });;
            }
          },
          (err) => {
            this.$message.error(JSON.stringify(err.response.data));
          }
        );
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
        this.$message({
          message: '请输入正确的手机号码',
          type: 'warning'
        });
      } else if (!this.code) {
        this.$message({
          message: '请输入验证码',
          type: 'warning'
        });
      }
    },
  },
};
</script>
<style scoped>
.select-class {
  width: 100%;
  min-height: 450px;
  overflow: hidden;
}

.box {
  width: 64%;
  margin-left: 17%;
  box-sizing: border-box;
  margin-top: 10px;
}

.nav-top {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.fromSite {
  width: 100%;
  /* margin-top: 40px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fromSite>span {
  font-size: 14px;
  color: #555;
  /* font-weight: 600; */
  margin-right: 20px;
}

.fromSite>div {
  width: 55%;
}

.fromSite /deep/ .el-date-editor {
  width: 100%;
}

.fromSite /deep/ .popper {
  max-height: 160px;
  overflow: auto;
}

.fromSite /deep/ .poppers {
  padding: 0;
}

.tostation {
  padding: 6px 12px;
  cursor: pointer;
}

.tostation:hover {
  background-color: #5cadad;
  color: #fff;
}

.query {
  width: 40%;
  background: #5cadad;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* 横向日期选择 */
.time {
  width: 95%;
  height: 60px;
  margin-top: 10px;
  background: #5cadad;
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 40px;
  height: 100%;
  line-height: 60px;
}

.icon:hover {
  background: rgb(129, 192, 192);
}

/* 129,192,192 */
.icon>i {
  width: 39px;
  color: #fff;
  font-weight: 900;
}

.time-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #fff;
}

.time-box>div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.time-box>div:hover {
  background: rgb(129, 192, 192);
}

.time-select {
  height: 100%;
  background: #fff;
  margin-top: 2px;
  color: #555;
}

.time-box>.time-select:hover {
  background: #fff;
}

/* 筛选选项 */
.filter {
  width: 100%;
}

.line {
  background: #5cadad;
  width: 100%;
  height: 2px;
  margin: 30px 0;
}

.class-list {
  width: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #5cadad;
  font-weight: 600;
  border-bottom: 1px dashed #5cadad;
  padding-bottom: 9px;
  box-sizing: border-box;
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.size1 {
  flex: 1;
  padding: 8px;
  box-sizing: border-box;
}

.size2 {
  flex: 2;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.column>span {
  font-size: 14px;
}

.list>div:nth-of-type(1)>span:nth-of-type(2) {
  color: #aaa;
}

.list>.size1 {
  font-size: 18px;
}

.list>div:nth-of-type(3) {
  color: #008000;
  font-weight: bold;
  font-size: 18px;
}

.list>div:nth-of-type(5)>span:nth-of-type(2) {
  font-size: 14px;
  color: #aaa;
}

.list>div:nth-of-type(6) {
  font-size: 14px;
}

.list>.button {
  /* width: 130px; */
  /* height: 80px; */
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  background: #5cadad;
  /* line-height: 80px; */
  cursor: pointer;
}

.no-class {
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  color: #ccc;
  font-weight: bold;
  margin-top: 10px;
}

.code-box {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.code-tip {
  margin-bottom: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.code-tip>.el-icon-circle-check {
  margin-right: 5px;
  color: #00C77B;
  font-size: 16px;
}

.code-box>.el-input {
  width: 60%;
}

.code-box>div:nth-of-type(2),
.code-box>div:nth-of-type(3) {
  width: 35%;
  background: #eee;
  border-radius: 2px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.code-box>div:nth-of-type(2) {
  cursor: pointer;
}
</style>