//会员系统
export const member = {
    getVerifyCode: 'verifyCode/v2/generate',//获取验证码
    login: 'oauth/token', //登录会员系统
    getPassengerList: 'user/passengerList', //获取常用乘车人列表
    addPassenger: 'user/addPassenger', //添加常用乘车人
    deletePassenger: 'user/deletePassenger', //删除常用乘车人
    editPassenger: 'user/editPassenger', //修改乘车人信息
    queryIntegral: 'user/queryIntegral', //查询积分余额
    queryIntegralList: 'integral/list', //积分明细分页查询
    queryOpenid: 'wxWebAuth/sns/oauth',//code获取openid 
}
//
// 'https://cjdev.jt169.com/api/app/wechat/activity/api/'
export const api = {
    authToken: 'oauth/token', //鉴权
    getStartStations: 'query/startstations', //获取起始站
    getToStation: 'query/tostations', //获取目的站
    queryClass: 'query/shiftList', //获取班次
    queryOneClass: 'query/class', //获取指定班次（获取的是实时数据）
    addOrder: 'trans/applyOrder', //提交申请
    getOrderList: 'query/orderByWx', //获取订单列表
    cancelOrder: 'trans/cancelOrder', //取消订单
    allTicketReturn: 'trans/ticketRefund', //整笔订单退票
    oneTicketReturn: 'trans/ticketRefundBySeat',//单张票退票
    queryReturn: 'query/changerstation',//判断是否有返回线路
    queryInsurance: 'query/getInsurancePrice',//查询保险
    pay: 'custom/pay/swiftWx/applyPay',//支付订单
    queryorderDetail: 'query/order', //订单详情查询
}