<template>
  <div class="home">
    <div class="swiper">
      <img src="../assets/swiper1.png" alt="" />
      <div class="search">
        <div class="fromSite">
          <span>出发地点</span>
          <div @click="selectStation">
            <el-input
              placeholder="请输入中文/简拼"
              suffix-icon="el-icon-location-outline"
              size="small"
              :readonly="true"
              v-model="fromSite"
            >
            </el-input>
          </div>
        </div>
        <div class="fromSite">
          <span>目的地点</span>
          <div>
            <el-input
              placeholder="请输入中文/简拼"
              suffix-icon="el-icon-location-outline"
              size="small"
              v-model="toSite"
              @input="StartInput"
              slot="reference"
            >
            </el-input>
            <el-popover
              placement="bottom"
              width="246"
              trigger="manual"
              v-model="isShow"
              :popper-class="searchList[0] ? 'popper' : 'poppers'"
            >
              <div
                v-show="searchList[0]"
                v-for="(item, index) in searchList"
                :key="index"
                class="tostation"
                @click="selectToStation(item)"
              >
                {{ item.toStationName }}
              </div>
              <div style="padding: 6px 12px" v-show="!searchList[0]">
                未匹配到站点信息
              </div>
            </el-popover>
          </div>
        </div>
        <div class="fromSite">
          <span>出发日期</span>
          <div>
            <el-date-picker
              v-model="date"
              type="date"
              placeholder="选择日期"
              size="small"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="query" @click="query">查询</div>
      </div>
    </div>
  </div>
</template>

<script>
import { memberUrl, apiUrl } from "../ajax/config.js";
import { mapState, mapMutations } from "vuex";
import { member, api } from "../ajax/api";
import util from "../util/util"
export default {
  name: "Home",
  props: {
    msg: String,
  },
  computed: {
    ...mapState(["token", "userId", "departrue", "destination", "authToken","selectedDate"]),
  },
  data() {
    return {
      fromSite: "", //起始地
      toSite: "", //目的地
      date: "", //日历选择器的日期
      toStationList: [], //目的站列表(筛选前源数据)
      searchList: [], //筛选后数据
      isShow: false, //目的站选择框是否显示
      pickerOptions: {}, //日历选择器配置项
    };
  },
  created() {
    let week = 7 * 24 * 3600 * 1000;
    this.pickerOptions.disabledDate = function (time) {
      // return time.getTime() < Date.now() - 8.64e7;
      // if (this.pickerMinDate) {
      let week = 7 * 24 * 3600 * 1000;
      // return Date.now() < (Date.now() - 8.64e6); /*结束日期要在选择的开始日期之后*/
      // } else {
      return (
        time.getTime() < Date.now() - 1 * 24 * 3600 * 1000 ||
        time.getTime() > Date.now() + week
      );
      // }
    };
  },
  mounted() {
    if (!this.date) {
      let nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(),
        month:
          nowDate.getMonth() + 1 > 9
            ? nowDate.getMonth() + 1
            : "0" + (nowDate.getMonth() + 1),
        day:
          nowDate.getDate() > 9 ? nowDate.getDate() : "0" + nowDate.getDate(),
      };
      this.date = date.year + "-" + date.month + "-" + date.day;
      // this.formatWeek(nowDate.getDay());
    }
    console.log('起始站',this.destination)
    // 起始目的站赋值
    this.fromSite = this.departrue.fromSite ? this.departrue.fromSite : "";
    this.toSite = this.destination.toStationName ? this.destination.toStationName : "";
    if(this.fromSite){
      this.getToStation();
    }
  },
  methods: {
    ...mapMutations(["setState"]),
    //去选择起始站
    selectStation() {
      this.$router.push({
        path: "/departrue",
      });
    },
    //目的地点输入
    StartInput(e) {
      let len = this.toStationList.length;
      this.searchList = [];
      if (!e) {
        this.isShow = false;
      } else {
        let reg = /^[A-Za-z]*$/; //判断输入的是否是字母
        if (reg.test(e)) {
          //如果是字母
          if (e.length == 1) {
            //如果输入是一个字母
            let toCase = e.toUpperCase();
            for (let i = 0; i < len; i++) {
              if (this.toStationList[i].toAbbr.charAt(0) == toCase) {
                this.searchList.push(this.toStationList[i]);
              }
            }
          } else {
            let toCase = e.toUpperCase();
            for (let i = 0; i < len; i++) {
              if (
                this.toStationList[i].toAbbr == toCase ||
                this.toStationList[i].toAbbr.indexOf(toCase) >= 0
              ) {
                this.searchList.push(this.toStationList[i]);
              }
            }
          }
        } else {
          //不是字母一律当中文处理
          for (let i = 0; i < len; i++) {
            if (this.toStationList[i].toStationName.indexOf(e) >= 0) {
              this.searchList.push(this.toStationList[i]);
            }
          }
        }
        console.log("筛选后", this.searchList);
        this.isShow = true;
      }
    },
    //选择目的站
    selectToStation(obj) {
      console.log("目的站");
      this.setState({
        name: "destination",
        key: {
          toStationId: obj.toStationId,
          toStationName: obj.toStationName,
        },
      });
      this.toSite = obj.toStationName;
      this.isShow = false;
    },
    //去查询目的班次
    query() {
      if (this.fromSite && this.toSite && this.date) {
        console.log('日期',util.formatTime(new Date(this.date)))
        this.setState({ name: "selectedDate", key: util.formatTime(new Date(this.date)) });
        this.$router.push({
          name: "Timeselect",
          params: {
            date: util.formatTime(new Date(this.date)),
            toStationList: this.toStationList
          }
        });
      }else if(!this.toSite){
        this.$message({
          message: '请输入目的地',
          type: 'warning'
        });
      }else if(!this.date){
        this.$message({
          message: '请选择下出发日期',
          type: 'warning'
        });
      }
    },
    // 获取目的站
    getToStation() {
      // this.$toast.loading({
      //   message: "获取中",
      //   forbidClick: true,
      //   duration: 0,
      // });
      this.$post(
        apiUrl + api.getToStation,
        {
          fromStationId: this.departrue.fromStationId,
          merge: 1,
          // returnType: 1,
        },
        {
          "x-tenant-flag": 1,
          Authorization: "Bearer " + this.authToken,
        }
      ).then(
        (res) => {
          console.log("目的站", res);
          if (res.code == 0) {
            this.toStationList = res.data;
          } else {
            // this.$toast(res.msg);
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        },
        (err) => {
          // this.$toast.clear();
          if (err.response.data.code == 401) {
            this.$emit("getAuthToken", "getToStation");
          } else {
            this.$message({
              message: JSON.stringify(err.response.data),
              type: "error",
            });
            // this.$toast(JSON.stringify(err.response.data));
          }
        }
      );
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 头部栏 */
.home {
  width: 100%;
  background: #ffffff;
  position: absolute;
}
.swiper {
  width: 100%;
  height: 330px;
  position: relative;
}
.swiper > img {
  width: 100%;
  height: 100%;
}
.search {
  min-width: 415px;
  height: 326px;
  background: #ffffff;
  position: absolute;
  top: 0px;
  left: 17%;
  box-shadow: 1px 3px 3px rgb(0 0 0 / 20%);
}
.fromSite {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fromSite > span {
  font-size: 14px;
  color: #555;
  font-weight: 600;
  margin-right: 20px;
}
.fromSite > div {
  width: 60%;
}

.fromSite /deep/ .popper {
  max-height: 160px;
  overflow: auto;
}
.fromSite /deep/ .poppers {
  padding: 0;
}

.tostation {
  padding: 6px 12px;
  cursor: pointer;
}

.tostation:hover {
  background-color: #5cadad;
  color: #fff;
}

.fromSite .el-date-editor {
  width: 100%;
}
.query {
  width: 40%;
  background: #5cadad;
  color: #ffffff;
  height: 34px;
  line-height: 34px;
  margin-top: 12%;
  margin-left: 30%;
}
</style>
