<template>
    <div class="mine">
        <!-- 左侧栏 -->
        <div class="left-box">
            <div class="select" @click="showOrderList">订单列表</div>
            <!-- <div>常用乘车人</div> -->
        </div>
        <!-- 右侧信息栏 -->
        <div class="right-box">
            <OrderList v-show="orderListShow" @orderListToggle="orderListToggle" @orderDetailToggle="orderDetailToggle"></OrderList>
            <OrderDetail v-if="orderDetailShow" @orderListToggle="orderListToggle" @orderDetailToggle="orderDetailToggle"></OrderDetail>
        </div>
        <!-- 登录框 -->
        <el-dialog title="七彩出行" :visible.sync="loginVisible" width="20%" center>
            <div class="code-tip" v-show="!codeTimeHidden">
                <i class="el-icon-circle-check"></i>动态验证码已经发送到您手机，请注意查收。
            </div>
            <div>
                <el-input placeholder="请输入手机号码" v-model="phone" clearable>
                </el-input>
            </div>
            <div class="code-box">
                <el-input placeholder="请输入手机号码" v-model="code">
                </el-input>
                <div v-show="codeTimeHidden" @click="getCode">获取验证码</div>
                <div v-show="!codeTimeHidden">{{ codeTime }}s后重新发送</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="login">登录</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { memberUrl, apiUrl } from "../ajax/config.js";
import { mapState, mapMutations } from "vuex";
import { member, api } from "../ajax/api";
import OrderList from "./orderlist.vue";
import OrderDetail from "./orderdetail.vue";
export default {
    components:{
        OrderList,
        OrderDetail
    },
    data() {
        return {
            loginVisible: false,//登录遮罩层是否显示
            loginVisible: false,//登录遮罩层是否显示
            phone: '',//电话号码
            code: '',//验证码
            codeTimeHidden: true,//验证码倒计时是否隐藏
            codeTime: 120,//获取验证码倒计时时间
            timer: null,//验证码倒计时定时器
            orderListShow: true,//订单列表组件是否显示
            orderDetailShow: false,//订单详情组件是否显示
        };
    },
    computed: {
        ...mapState(["token", "authToken"]),
    },
    mounted() {
        if (!this.token) {
            this.loginVisible = true;
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        //订单列表组件显示隐藏
        orderListToggle(type){
            this.orderListShow = type;
        },
        //订单详情组件显示隐藏
        orderDetailToggle(type){
            console.log('订单详情组件显示隐藏',type)
            this.orderDetailShow = type;
        },
        //
        showOrderList(){
            this.orderListToggle(true)
            this.orderDetailToggle(false)
        },
        //获取登录验证码
        getCode() {
            if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
                this.codeTimeHidden = false;
                this.codeDown()
                this.$post(
                    memberUrl + member.getVerifyCode,
                    { phone: this.phone, scenes: 2 },
                    { "x-tenant-flag": 1 }
                )
                    .then(
                        (res) => {
                            console.log("验证码", res);
                            if (res.code == 0) {
                                this.$message({
                                    message: '发送成功。',
                                    type: 'success'
                                });
                            }
                        },
                        (err) => {
                            this.$message.error(JSON.stringify(err.response.data));
                        }
                    );
            } else {
                this.$message({
                    message: '请输入正确的手机号码',
                    type: 'warning'
                });
            }
        },
        //验证码倒计时
        codeDown() {
            let self = this;
            self.timer = setInterval(function () {
                if (self.codeTime > 0) {
                    self.codeTime -= 1;
                } else {
                    clearInterval(self.timer)
                    self.codeTimeHidden = true;
                    self.codeTime = 120;
                }
            }, 1000)
        },
        //登录
        login() {
            if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone) && this.code) {
                this.$post(
                    memberUrl + member.login,
                    this.$qs.stringify({
                        loginType: 2,
                        username: this.phone,
                        category: 1,
                        smsCode: this.code,
                        grant_type: "password",
                    }),
                    {
                        "x-tenant-flag": 1,
                        "content-type": "application/x-www-form-urlencoded",
                        Authorization: "Basic YXBwOmFwcA==",
                    }
                ).then(
                    (res) => {
                        console.log("登录", res);
                        if (res.access_token) {
                            this.code = "";
                            this.$store.commit("setToken", {
                                token: res.access_token,
                                userId: res.userId,
                            });
                            sessionStorage.setItem("token", res.access_token);
                            sessionStorage.setItem("userId", res.userId);
                            this.$message({
                                message: '登录成功。',
                                type: 'success'
                            });
                            this.loginVisible = false;
                        }
                    },
                    (err) => {
                        this.$message.error(JSON.stringify(err.response.data));
                    }
                );
            } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
                this.$message({
                    message: '请输入正确的手机号码',
                    type: 'warning'
                });
            } else if (!this.code) {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning'
                });
            }
        },
    },
};
</script>
<style scoped>
.code-box {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.code-tip {
    margin-bottom: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.code-tip>.el-icon-circle-check {
    margin-right: 5px;
    color: #00C77B;
    font-size: 16px;
}

.code-box>.el-input {
    width: 60%;
}

.code-box>div:nth-of-type(2),
.code-box>div:nth-of-type(3) {
    width: 35%;
    background: #eee;
    border-radius: 2px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.code-box>div:nth-of-type(2) {
    cursor: pointer;
}
.mine{
    display: flex;
    width: 64%;
    margin-left: 18%;
}
.right-box{
    margin-left: 30px;
    margin-top: 20px;
    width: 80%;
}
.left-box{
    width: 18%;
    height: 40px;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background-color: #fafafa;
    margin-top: 20px;
}
.left-box>div{
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-right: 1px solid #eee;
}
.left-box .select{
    position: relative;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-right: none;
    color: #ff6257;
    background-color: #fff;
    cursor: pointer;
}
.select:before{
    content: "";
    border-left: 2px solid #ff6257;
    display: block;
    position: absolute;
    left: 0;
    height: 40px;
}
</style>