
export const memberUrl = 'https://cm.jt169.com/api/ac88ceb386aa4231b09bf472cb937c24/app/'; //baseurl
// export const memberUrl = 'https://bctest.jt169.com:11443/api/ac88ceb386aa4231b09bf472cb937c24/app/'; //baseurl
//export const memberUrl = 'https://cjdev.jt169.com:11443/api/ac88ceb386aa4231b09bf472cb937c24/app/'; //baseurl

export const apiUrl = 'https://ticketv3service.jt169.com/api/b720c70ed9124f35963cf029e97fa72e/ticket/';
// export const apiUrl = 'https://clearing.jt169.com:11443/api/ac88ceb386aa4231b09bf472cb937c24/ticket/';
//export const apiUrl = 'https://clearing.jt169.com:11443/api/b720c70ed9124f35963cf029e97fa72e/ticket/';


/**
 * 测试环境
 * OAT 设置
 */
// export const  appId="vs353m5of437a3b5883k";
// export const  appKey="0p9c6i8ao0gi0yel83d426f6zl6p3f5i"


/**
 * 正式环境
 * OAT 设置
 */
export const  appId="b931kjo0ek21a53d1o3h";
export const  appKey="5c26eb4l33e07w3c3wz19io220tr2hgf"
