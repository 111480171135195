import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js'
import router from './router/index.js'
import ElementUI from 'element-ui';
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'element-ui/lib/theme-chalk/index.css';
import VRouter from "vue-router";

import qs from 'qs'
Vue.use(VueAxios,axios);
Vue.use(VRouter);
Vue.use(ElementUI);
Vue.config.productionTip = false;

Vue.prototype.$axios = axios
import { post, get, patch, put } from './ajax/http'
// import util from "./util/util"
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$patch = patch;
Vue.prototype.$put = put;
// Vue.prototype.$util = util;

Vue.prototype.$qs = qs;

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
