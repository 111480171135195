<template>
    <div class="pay" v-if="orderInfo.payFare" v-loading="loading" element-loading-text="获取中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <h2 class="pay-title">
            支付金额<span>{{ orderInfo.payFare }}</span>元，请使用微信或支付宝扫描下方二维码进行支付
        </h2>
        <div class="tip">请您尽快支付，避免支付超时为您出行带来不便</div>
        <div class="ercode">
            <div id="qrcode" ref="qrcode"></div>
            <div>请打开<span>手机微信</span>或者<span>手机支付宝</span>的<span>"扫一扫"</span></div>
            <div>扫描上方二维码进行支付</div>
        </div>
    </div>
</template>
<script>
import { apiUrl } from "../ajax/config.js";
import { api } from "../ajax/api";
import { mapState, mapMutations } from "vuex";
import QRCode from 'qrcodejs2'
export default {
    data() {
        return {
            orderInfo: {},//订单信息
            loading: false,//
            timer: null,//查询订单状态定时器
        };
    },
    computed: {
        ...mapState(["token", "authToken"]),
    },
    mounted() {

    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    created() {
        this.loading = true;
        this.orderInfo = this.$route.params.orderInfo;
        console.log('订单信息', this.orderInfo)
        this.$post(apiUrl + api.pay, {
            channelId: "SWIFT_UNIFIED_NATIVE",
            orderNo: this.orderInfo.orderToken
        },
            {
                "x-tenant-flag": 1,
                Authorization: "Bearer " + this.authToken,
            }).then(res => {
                console.log('支付', res)
                this.loading = false;
                if (res.code == 0) {
                    new QRCode("qrcode", {
                        text: "" + res.data.codeUrl,
                        width: 280,
                        height: 280,
                        colorDark: "#333333", // 二维码颜色
                        colorLight: "#ffffff", // 二维码背景色
                        correctLevel: QRCode.CorrectLevel.H, // 容错率，L/M/H
                    });
                    this.queryStatus()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'info'
                    });
                }
            }, (err) => {
                this.loading = false;
                this.$message.error(JSON.stringify(err.response.data));
            })
    },
    methods: {
        //查询订单是否出票
        queryStatus(){
            let self = this;
            self.timer = setInterval(function(){
                self.queryOrder()
            },5000)
        },
        //查询订单状态接口
        queryOrder(){
            this.$post(apiUrl + api.queryorderDetail,{
                orderToken: this.orderInfo.orderToken
            },{
                "x-tenant-flag": 1,
                Authorization: "Bearer " + this.authToken,
            }).then(res=>{
                if(res.data[0] && res.data[0].orderStatus == 3){
                    clearInterval(this.timer)
                    this.$message({
                        message: '出票成功',
                        type: 'success'
                    })
                    console.log('查询订单状态',res)
                    this.$router.go(-1)
                }
            },err=>{
                this.$message.error(JSON.stringify(err.response.data));
            })
        }
    },
};
</script>
<style scoped>
.pay{
    min-height: 400px;
}
.pay-title {
    padding: 30px 0
}

.pay-title>span {
    color: #f60;
    margin: 0 5px;
}

.tip {
    font-size: 16px;
    color: #f60;
}
.ercode{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
}
.ercode>div>span{
    color: #f60;
}
.ercode>div{
    margin-top: 10px;
}
#qrcode{
    margin-bottom: 20px;
}
</style>