<template>
    <div class="order" v-loading="loading" element-loading-text="查询中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" v-if="classInfo.station">
        <div>
            <div class="line-info">
                <div class="line-info-title">
                    <span>车次信息</span>
                </div>
                <div class="info-line">
                    <div class="car-type">
                        <span>计划车型：</span>
                        <span>客车</span>
                    </div>
                    <div class="line">
                        <div>
                            <span>始</span>
                            <span>{{ classInfo.station.name }}</span>
                        </div>
                        <div>
                            {{ classInfo.classDateTime }}
                        </div>
                        <div>
                            <span>终</span>
                            <span>{{ classInfo.toStation.stationName }}</span>
                        </div>
                    </div>
                    <div style="color: #ff6257;">
                        ￥{{ fare }}
                    </div>
                </div>
            </div>
            <div class="passenger-info">
                <div class="passenger-info-title">
                    <span>填写信息</span>
                </div>
                <div>
                    乘车人信息
                    <span>（暂不支持购买儿童票）</span>
                </div>
                <div class="passenger-list">
                    <span>选择乘车人</span>
                    <div v-for="(item, index) in passengerList" :key="index" @click="selectPassenger(index)">
                        <img src="../assets/no_select.png" alt="" v-show="!item.select" />
                        <img src="../assets/select.png" alt="" v-show="item.select" />
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div class="passenger-info-list" v-for="(item, index) in addPassengerList" :key="index">
                    <div>
                        <div>
                            <div class="label">姓名</div>
                            <el-input v-model="item.name" placeholder="与证件姓名保持一致" size="small"></el-input>
                        </div>
                        <div>
                            <div class="label">手机号码</div>
                            <el-input v-model="item.phoneNumber" placeholder="请填写正确的电话号码" size="small"
                                clearable></el-input>
                        </div>
                        <div>
                            <div class="label">证件信息</div>
                            <el-input v-model="item.idCard" maxlength="18" placeholder="与证件姓名保持一致" size="small"
                                clearable></el-input>
                        </div>
                        <div class="contact-select" @click="selectContact(index)">
                            <img src="../assets/no_select.png" alt="" v-show="!addPassengerList[index].select" />
                            <img src="../assets/select.png" alt="" v-show="addPassengerList[index].select" />
                            <span>设为取票人</span>
                        </div>
                    </div>
                    <img src="../assets/delete.png" alt="" @click="deletePassengerInfo(index)">
                </div>
                <div class="add-passenger">
                    <div @click="addInfoVisible = true">+新增乘客</div>
                    <div>每笔订单最多添加<span>5</span>名乘客</div>
                </div>
                <div class="get-ticket-info">
                    <div>取票人信息</div>
                    <div>
                        <div>
                            <div class="get-ticket-label">姓名</div>
                            <el-input v-model="contact.name" placeholder="取票人姓名" size="mini"></el-input>
                        </div>
                        <div>
                            <div class="get-ticket-label">手机号码</div>
                            <el-input v-model="contact.phoneNumber" maxlength="11" placeholder="接收出票信息联系手机" size="mini"
                                clearable></el-input>
                        </div>
                        <div>
                            <div class="get-ticket-label">二代身份证</div>
                            <el-input v-model="contact.idCard" maxlength="18" placeholder="与证件姓名保持一致" size="mini"
                                clearable></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-info">
                <div>
                    共<span>{{ count }}</span>名乘车人 出发时间：<span>{{ classInfo.classDateTime }}</span>
                </div>
                <div @click="addOrder">提交订单</div>
            </div>
        </div>
        <div class="notice">
            <div class="fare-info">
                <div class="fare-info-title">
                    <span>票价信息</span>
                    <img src="../assets/top.png" alt="" v-show="priceDetailShow" @click="priceDetailToggle">
                    <img src="../assets/down.png" alt="" v-show="!priceDetailShow" @click="priceDetailToggle">
                </div>
                <div class="price-info" v-show="priceDetailShow">
                    <div>
                        <span>票价：</span>
                        <span>{{ fare }}x{{ count }}</span>
                    </div>
                    <div>
                        <span>服务费：</span>
                        <span>{{ serviceFee }}x{{ count }}</span>
                    </div>
                </div>
                <div class="price-all">
                    <span>订单总额</span>
                    <span>￥{{ price }}</span>
                </div>
            </div>
            <div class="tip">
                <div class="tip-title">温馨提示</div>
                <div>
                    <div>1、支付成功后，我们会及时短信通知您购票结果；您也可以到订单中心查看出票情况。</div>
                    <div>2、如您在发车前30分钟还未收到车票相关信息，请及时拨打客服电话进行核实。</div>
                    <div>3、请提前至少30分钟（节假日等高峰期建议提前一小时），凭购票人的身份证件前往出发车站取票。 如您购票未成功，您的款项将在<span>1-7个工作日</span>内全额原路退至您的支付账户。
                    </div>
                </div>
            </div>
        </div>
        <!-- 添加乘车人框 -->
        <el-dialog title="添加乘车人信息" :visible.sync="addInfoVisible" width="20%" center :before-close="handleClose">
            <div>
                <div>姓名</div>
                <el-input placeholder="与证件姓名保持一致" v-model="addName">
                </el-input>
            </div>
            <div>
                <div>手机号码</div>
                <el-input placeholder="请输入手机号码" maxlength="11" v-model="addPhone" clearable>
                </el-input>
            </div>
            <div>
              <div>证件类型</div>
              <el-select placeholder="请选择证件类型"  v-model="addCardType" >
                <el-option
                  v-for="item in cardTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
            </div>
            <div>
                <div>证件号</div>
                <el-input placeholder="与证件号码保持一致" maxlength="18" v-model="addIdcard" clearable>
                </el-input>
            </div>
          <div>
            <div>性别</div>
            <el-select placeholder="请选择性别" v-model="addGender">
              <el-option
                v-for="item in genderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
          </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { memberUrl, apiUrl } from "../ajax/config.js";
import { mapState, mapMutations } from "vuex";
import { member, api } from "../ajax/api";
import util from "../util/util";
export default {
    data() {
        return {
            loading: false,//调用接口查询loading
            classInfo: {},//班次信息
            fare: 0,//票价
            serviceFee: 0,//服务费
            passengerList: [],//乘车人列表
            addPassengerList: [],//添加的乘车人列表
            contact: {},//联系人
            count: 0,//选择乘车人数量
            price: 0,//应支付金额
            addInfoVisible: false,//添加乘车人信息框是否显示
            addName: '',//添加乘车人信息框的姓名
            addPhone: '',//添加乘车人信息框的电话
            addCardType:1,
            addIdcard: '',//添加乘车人信息框的身份证号
            addGender:1,
            priceDetailShow: false,//费用详情是否显示
          cardTypeList:[
            {
              value:1,
              label:"身份证"
            },
            {
              value:17,
              label:"港澳居民来往内地通行证"
            },
            {
              value:19,
              label:"台湾居民来往大陆通行证"
            },
            {
              value:14,
              label:"港澳居民居住证"
            },
            {
              value:15,
              label:"台湾居民居住证"
            },
            {
              value:13,
              label:"按规定可使用的有效护照"
            },
            {
              value:16,
              label:"外国人永久居留身份证"
            }
          ],
          genderList:[
            {
              value:1,
              label:'男'
            },
            {
              value:2,
              label:'女'
            },
          ]
        }
    },
    computed: {
        ...mapState(["token", "userId", "departrue", "destination", "authToken", "selectedDate", "classId"]),
    },
    created() {
        this.getClassInfo();
    },
    mounted() {
        console.log('选择的日期', this.addPassengerList)
    },
    methods: {
        // 查询班次详情
        getClassInfo() {
            this.loading = true;
            this.$post(
                apiUrl + api.queryOneClass,
                {
                    classId: this.classId,
                    showPassStation: 2,
                    toStationId: this.destination.toStationId,
                },
                {
                    "x-tenant-flag": 1,
                    Authorization: "Bearer " + this.authToken,
                }
            ).then(
                (res) => {
                    console.log("班次详情", res);
                    this.loading = false;
                    if (res.code == 0) {
                        this.classInfo = res.data[0];
                        // this.ticketNumber = res.data[0].remSeat > 0 ? true : false;
                        this.fare = res.data[0].price;
                        // let date = res.data[0].classDate.split("-");
                        // this.sendDate = date[0] + "年" + date[1] + "月" + date[2] + "日";
                        this.serviceFee = res.data[0].servicePrice; //服务费
                        this.getPassengerList();
                    } else {
                        this.$message.error(res.msg);
                    }
                },
                (err) => {
                    this.loading = false;
                    this.$message.error(JSON.stringify(err.response.data));
                }
            );
        },
        //获取乘车人列表
        getPassengerList() {
            this.passengerList = [];
            this.loading = true;
            this.$post(
                memberUrl + member.getPassengerList,
                this.$qs.stringify({
                    access_token: this.token,
                }),
                {
                    "x-tenant-flag": 1,
                    Authorization: this.token,
                    "content-type": "application/x-www-form-urlencoded",
                }
            ).then(
                (res) => {
                    console.log('乘车人', res)
                    this.loading = false;
                    if (res.code == 0) {
                        let len = res.data.length;
                        if (len > 0) {
                            for (let i = 0; i < len; i++) {
                                this.passengerList.push({
                                    name: res.data[i].passengerName,
                                    phoneNumber: res.data[i].passengerPhone,
                                    idCard: res.data[i].passengerCard,
                                    cardType: res.data[i].passengerCardType,
                                    id: res.data[i].id,
                                    select: false,
                                });
                            }
                        }
                        if (this.addPassengerList[0]) {
                            let len1 = this.addPassengerList.length;
                            for (let i = 0; i < len1; i++) {
                                for (let j = 0; j < len; j++) {
                                    if (this.addPassengerList[i].id == this.passengerList[j].id) {
                                        console.log('已经选择了的乘车人', this.addPassengerList[i], this.passengerList[j])
                                        this.passengerList[j].select = true;
                                    }
                                }
                            }
                        }
                        if (this.classInfo.remSeat <= 0) {
                            this.$message({
                                message: '该班次车票已售完！',
                                type: 'info'
                            });
                        }
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'info'
                        });
                    }
                },
                (err) => {
                    this.loading = false;
                    this.$message.error(JSON.stringify(err.response.data));
                }
            );
        },
        //选择乘车人
        selectPassenger(index) {
            console.log('选择的人数', this.count)
            if (this.passengerList[index].select) {
                this.passengerList[index].select = false;
                this.count--;
                let len = this.addPassengerList.length;
                for (let i = 0; i < len; i++) {
                    if (this.addPassengerList[i].id == this.passengerList[index].id) {
                        this.addPassengerList.splice(i, 1);
                        break;
                    }
                }
            } else {
                if (this.count < 5) {
                    this.passengerList[index].select = true;
                    this.count++;
                    this.addPassengerList.push({
                        name: this.passengerList[index].name,
                        phoneNumber: this.passengerList[index].phoneNumber,
                        idCard: this.passengerList[index].idCard,
                        cardType: this.passengerList[index].cardType,
                        id: this.passengerList[index].id,
                        select: false
                    })
                } else {
                    this.$message({
                        message: '每笔订单最多添加5名乘客',
                        type: 'warning'
                    });
                }
            }
            this.price = (Number(this.fare) + Number(this.serviceFee)) * this.count;
            console.log('选择的乘车人', this.addPassengerList)
        },
        //保存乘车人信息
        save() {

            if (util.checkCard(this.addIdcard,this.addIdcard) && /^1(3|4|5|6|7|8|9)\d{9}$/.test(this.addPhone) && this.addName&&this.addCardType!=0) {
                this.juge()
            } else if (!this.addName) {
                this.$message({
                    message: '请输入姓名',
                    type: 'warning'
                });
            } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.addPhone)) {
                this.$message({
                    message: '请输入正确的电话号码',
                    type: 'warning'
                });
            }else  if (this.addCardType==0){
              this.$message({
                message:'请选择证件类型',
                type:'warning'
              })
            }
            else if (!util.checkCard(this.addIdcard,this.addCardType)) {
                this.$message({
                    message: '请输入正确的身份证号',
                    type: 'warning'
                });
            }
        },
        //删除选择的乘车人
        deletePassengerInfo(index) {
            let len = this.passengerList.length;
            this.count--;
            for (let i = 0; i < len; i++) {
                if (this.passengerList[i].id == this.addPassengerList[index].id) {
                    this.addPassengerList.splice(index, 1)
                    this.passengerList[i].select = false;
                    break;
                }
            }
            this.price = (Number(this.fare) + Number(this.serviceFee)) * this.count;
        },
        //添加时判断身份证号是否重复
        juge() {
            let boll = false;
            let arr = this.passengerList;
            let len = this.passengerList.length;
            for (let i = 0; i < len; i++) {
                if (arr[i].idCard == this.addIdcard) {
                    boll = true;
                    break;
                }
            }
            if (boll) {
                this.$message({
                    message: '身份证号已保存过，请检查清楚',
                    type: 'warning'
                })
            } else {
                this.addPassengerInfo()
            }
        },
        //添加常用乘车人接口
        addPassengerInfo() {
            this.loading = true;
            this.$post(
                memberUrl + member.addPassenger,
                this.$qs.stringify({
                    access_token: this.token,
                    phone: this.addPhone,
                    name: this.addName,
                    cardType: this.addCardType,
                    card: this.addIdcard,
                    // gender: this.addIdcard.slice(16, 17) % 2 == 0 ? 2 : 1,
                  gender:this.addGender
                }),
                {
                    "x-tenant-flag": 1,
                    Authorization: this.token,
                    "content-type": "application/x-www-form-urlencoded",
                }
            ).then(
                (res) => {
                    this.loading = false;
                    console.log("添加乘车人", res);
                    if (res.code == 0) {
                        this.addInfoVisible = false;
                        // this.addPassengerList = [];
                        this.getPassengerList()
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }
                },
                (err) => {
                    this.loading = false;
                    this.$message.error(JSON.stringify(err.response.data));
                }
            );
        },
        //添加乘车人信息弹窗关闭前
        handleClose() {
            this.addName = '';
            this.addIdcard = '';
            this.addPhone = '';
            this.addInfoVisible = false;
        },
        //选择联系人
        selectContact(index) {
            let len = this.addPassengerList.length;
            for (let i = 0; i < len; i++) {
                if (i == index) {
                    this.addPassengerList[i].select = true;
                    this.contact = {
                        name: this.addPassengerList[i].name,
                        phoneNumber: this.addPassengerList[i].phoneNumber,
                        idCard: this.addPassengerList[i].idCard,
                    }
                } else {
                    this.addPassengerList[i].select = false;
                }
            }
        },
        //费用详情显示隐藏
        priceDetailToggle() {
            this.priceDetailShow = !this.priceDetailShow;
        },
        //提交订单
        addOrder(){
            this.loading = true;
            if (!this.contact.name) {
                this.loading = false;
                this.$message({
                    message: "请选择联系人!",
                    type: 'warning'
                });
            } else if (this.count <= 0) {
                this.loading = false;
                this.$message({
                    message: "请选择乘车人!",
                    type: 'warning'
                });
            } else {
                let list = [];
                let nameBoll = false;//乘车人姓名是否为空
                let idCardBoll = false;//乘车人身份证号是否正确
                let phoneBoll = false;//乘车人电话是否正确
                let passengers = this.addPassengerList;
                let len = this.addPassengerList.length;
                for (let i = 0; i < len; i++) {
                    if (passengers[i].name && util.checkCard(passengers[i].idCard,passengers[i].cardType) && /^1(3|4|5|6|7|8|9)\d{9}$/.test(passengers[i].phoneNumber)) {
                        list.push({
                            ticketType: 1,
                            passengerName: passengers[i].name,
                            phone: passengers[i].phoneNumber,
                            cardType: passengers[i].cardType,
                            card: passengers[i].idCard,
                            isChild: 0,
                            ticketPrice: this.fare,
                        });
                    } else {
                        list = [];
                        if(!passengers[i].name){
                            nameBoll = true;
                            break;
                        }else if(!util.checkCard(passengers[i].idCard,passengers[i].cardType)){
                            idCardBoll = true;
                            break;
                        }else if(!/^1(3|4|5|6|7|8|9)\d{9}$/.test(passengers[i].phoneNumber)){
                            phoneBoll = true;
                            break;
                        }
                    }
                }
                if (nameBoll) {
                    this.loading = false;
                    this.$message({
                        message: "请填写乘车人姓名",
                        type: 'warning'
                    });
                }else if(phoneBoll){
                    this.loading = false;
                    this.$message({
                        message: "请填写正确的电话号码",
                        type: 'warning'
                    });
                }else if(idCardBoll){
                    this.loading = false;
                    this.$message({
                        message: "请填写正确的身份证号",
                        type: 'warning'
                    });
                } else {
                    this.$post(
                        apiUrl + api.addOrder,
                        {
                            orderNo: util.getRandomCode(16),
                            fromStationId: this.classInfo.station.id,
                            classId: this.classInfo.classId,
                            toStationId: this.classInfo.toStation.stationId,
                            lockMin: 5,
                            lockTicketCount: this.count,
                            totalTicketFee: this.fare * this.count,
                            servicePrice: this.serviceFee,
                            totalServicePrice: this.serviceFee * this.count,
                            totalPay: this.price,
                            appUserId: this.userId,
                            bookerInfo: {
                                bookerName: this.contact.name,
                                phone: this.contact.phoneNumber,
                                cardType: 0,
                                card: this.contact.idCard,
                            },
                            passengerInfo: list,
                        },
                        {
                            "x-tenant-flag": 1,
                            Authorization: "Bearer " + this.authToken,
                        }
                    ).then(
                        (res) => {
                            console.log("提交订单", res);
                            this.loading = false;
                            if (res.code == 0) {
                                this.$router.replace({
                                    name: "Pay",
                                    params: {
                                        orderInfo: res.data[0],
                                    },
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'info'
                                });
                            }
                        },
                        (err) => {
                            this.loading = false;
                            this.$message.error(JSON.stringify(err.response.data));
                        }
                    );
                }
            }
        },
    },
}
</script>
<style scoped>
.order {
    width: 64%;
    margin-left: 17%;
    display: flex;
    margin-top: 10px;
}

.order>div:nth-of-type(1) {
    flex: 3;
    text-align: left;
}



.line-info {
    border: 1px solid #ddd;
}

.line-info-title,
.passenger-info-title {
    width: 100%;
    height: 40px;
    background: #f0f0f0;
    font-size: 20px;
    line-height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    color: #333333;
}

.info-line {
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.car-type {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.car-type>span:nth-of-type(1) {
    font-size: 12px;
    color: #999999;
}

.line {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.line>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}

.line>div:nth-of-type(1)>span:nth-of-type(1) {
    color: #fff;
    background: #58B8EA;
    border-radius: 2px;
    font-size: 12px;
    padding: 1px;
    box-sizing: border-box;
    margin-right: 5px;
}

.line>div:nth-of-type(2) {
    font-size: 14px;
    margin: 0 20px;
    border-bottom: 1px solid #CCCCCC;
}

.line>div:nth-of-type(3) {
    display: flex;
    align-items: center;
}

.line>div:nth-of-type(3)>span:nth-of-type(1) {
    color: #fff;
    background: #56D7AE;
    border-radius: 2px;
    font-size: 12px;
    padding: 1px;
    box-sizing: border-box;
    margin-right: 5px;
}

.passenger-info {
    border: 1px solid #ddd;
    margin-top: 30px;
}

.passenger-info>div:nth-of-type(2) {
    font-size: 18px;
    padding: 10px;
    padding-left: 20px;
    box-sizing: border-box;
}

.passenger-info>div:nth-of-type(2)>span {
    font-size: 12px;
    color: #999999;
}

.passenger-list {
    background: #f0f0f0;
    padding: 10px;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}

.passenger-list>div {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.passenger-list>div>img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.passenger-info-list {
    background: #f5f5f5;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 200px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.passenger-info-list>div {
    position: relative;
    font-size: 14px;
}

.contact-select {
    position: absolute;
    right: -28%;
    top: 3%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.contact-select>img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.passenger-info-list>img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.passenger-info-list>div>div {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.label {
    min-width: 70px;
    margin-right: 5px;
    font-size: 14px;
}

.add-passenger {
    margin: 0px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 20px;
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.add-passenger>div:nth-of-type(1) {
    background: #FF6257;
    color: #fff;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 2px;
    font-size: 14px;
    cursor: pointer;
}

.add-passenger>div:nth-of-type(2) {
    font-size: 14px;
    color: #333333;
    margin-left: 30px;
}

.add-passenger>div:nth-of-type(2)>span {
    color: #ff6257;
}

.get-ticket-info {
    padding: 20px;
    box-sizing: border-box;
}

.get-ticket-info>div:nth-of-type(2) {
    display: flex;
    margin-top: 15px;
}

.get-ticket-info>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 20px;
}

.get-ticket-info .get-ticket-label:nth-of-type(1) {
    margin-right: 10px;
    font-size: 14px;
    min-width: 30px;
}

.el-input {
    width: 65%;
}
.el-select{
  width: 70%;
}

.el-dialog__body>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.el-dialog__body .el-input {
    width: 70%;
}

.bottom-info {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.bottom-info>div>span {
    color: #ff6257;
}

.bottom-info>div:nth-of-type(2) {
    width: 150px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #ff6257;
    color: #fff;
    font-size: 18px;
    border-radius: 2px;
    cursor: pointer;
}

.notice {
    flex: 1;
    text-align: center;
    margin-left: 30px;
}

.fare-info {
    border: 1px solid #ddd;
}

.fare-info-title {
    height: 40px;
    background: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 20px;
    color: #333;
}

.fare-info-title>img {
    width: 20px;
    height: 20px;
}

.price-info {
    padding: 20px;
    color: #666;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
}

.price-info>div {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 5px;
}

.price-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 15px 0;
}

.price-all>span:nth-of-type(2) {
    font-size: 22px;
    color: #ff6257;
}

.tip {
    border: 1px solid #ddd;
    margin-top: 20px;
}

.tip-title {
    height: 40px;
    background: #f0f0f0;
    line-height: 40px;
    text-align: left;
    padding: 0 20px;
    font-size: 20px;
    color: #333;
}

.tip>div:nth-of-type(2) {
    color: #999999;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: justify;
    padding: 0 20px;
}

.tip>div:nth-of-type(2)>div {
    margin-bottom: 10px;
}
</style>
